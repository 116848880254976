/**
 * Add any environment variables you need here
 */
export default {
    COMPANY_NAME: 'The Flowery',
    STORE_URL: 'https://api.theflowery.co',
    PLATFORM_URL: 'https://app.getsalve.co',
    SALVE_TOKEN: '3931|H9jGseC8IWucUdlNmcCRParxGrdhFksKpP08E8hp',
    APP_ENV: 'production',
    GOOGLE_TOKEN: 'G-Q8XNDVG5XJ',
    GOOGLE_APP_NAME: 'Flowery eCommerce',
    ZENDESK_TOKEN: '8a2271ef-dfac-4682-bd5f-236439ce108f',
    STORE_ROUTES: {
        // Fetches data for the navbar and footer
        NAVIGATION_DATA: '/wp-json/acf/v3/options/options',
    },
    STORE_FEATURES: {
        IN_STORE_PICKUP: true,
    },
    IMAGE_KIT_URL: 'https://ik.imagekit.io/salve/',
    STORE_CONFIG: {
        EMPLOYEE_ONLY_PICKUP_LOCATIONS: [2],
        DEFAULT_LOCATION_ID: 2,
        HOMESTEAD_ID: 2,
    },
    SENTRY_DSN: 'https://6c9274fbe33b4ad4039c529a7fbb7a42@o1103062.ingest.sentry.io/4505885334044672',
    SENTRY_AUTH_TOKEN: '0ce40e3d851a84bfe8aa817082a50c0dec0dbf5c4ec06015782ead145eb7f276',
};
